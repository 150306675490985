import React,{useEffect} from 'react';
import styled from 'styled-components';
import Logo from '@assets/img/logo.png';
import ActivityIndicator from './ActivityIndicator';

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
`;

export default function Preloader() {

    useEffect(() => {
        document.body.style.overflowY = "hidden";
    },[])

    return (
        <Container className="bg-bubbles">
            <img className="header-logo__img" src={Logo} alt="logo" />
            <ActivityIndicator />
        </Container>
    );
}
