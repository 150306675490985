import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import InfographicsCard from './components/InfographicsCard';
import GavelBaseInit,{config} from '@components/GavelBase';
import Preloader from '@components/Preloader';
import PagesHeader from '@components/PagesHeader'
import InfographicsImage from '@assets/img/Group 255.jpg';



export default function Infographics() {
    const [infographics, setInfographics] = useState();

    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('fetch/infographics');
            getter.on('static', (data) => setInfographics(data.Details));
        })();
    }, []);

    return (
        <>
            {infographics ? (
                <MainContainer>
                    <main class="main">
                    <PagesHeader backgroundImage={InfographicsImage} preTitle="Documents" title={<><span>Our</span> <span>Infographics</span></>} />
                        <section class="section about-us">
                            <div class="container">
                                <div class="row offset-margin">
                                    {infographics.map((data, index) => {
                                        return (
                                            <InfographicsCard
                                                title={data.Title}
                                                Image={config.apiUrl + data.DisplayImage}
                                                key={index}
                                                FileFormat={data.FileFormat}
                                                FileLink={config.apiUrl + data.FileLink}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
