import React, { useState, useEffect } from 'react';
import MainContainer from '@components/MainContainer';
import LandingPageHeader from './components/Header';
import Ranking from './components/Ranking';
import Impacts from './components/Impact';
import AboutUs from './components/AboutUs';
import Stories from './components/Stories';
import Infographics from './components/Infographics';
import Volunteer from './components/Volunteer';
import Testimonials from './components/Testimonials';
import Newsletter from './components/Newsletter';
import Donors from './components/Donors';
import GavelBaseInit from '@components/GavelBase';
import Preloader from '@components/Preloader';
import NewBlogs from './components/NewBlogs';

export default function LandingPage() {
    const [homepage, setHomepage] = useState();

    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('home/all');
            getter.on('static', (data) => setHomepage(data.Details));
        })();
    }, []);

    return (
        <>
            {homepage ? (
                <MainContainer>
                    <LandingPageHeader data={homepage.Sliders} settingData={homepage.Settings} />
                    <Ranking data={homepage.Settings} />
                    <Impacts data={homepage.Settings} />
                    <AboutUs data={homepage.Projects} />
                    <NewBlogs data={homepage.BlogPosts} />
                    <Stories data={homepage.Stories} />
                    <Infographics data={homepage.Infographics} />
                    <Volunteer />
                    <Testimonials data={homepage.Testimonials} />
                    <Newsletter />
                    <Donors data={homepage.Sponsors} />
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
