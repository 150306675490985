const GavelBaseInstance = async (config) => {
    const GavelBaseStructure = {};

    GavelBaseStructure.config = config;

    GavelBaseStructure.post = async (request, data = {}, uniqueUri = false) => {
        const requestBody = {};
        requestBody.method = 'POST';
        requestBody.credentials = 'same-origin';
        requestBody.body = JSON.stringify(data),
        requestBody.headers = {
            'Content-Type': 'application/json',
            'access-control-allow-origin': '*',
        };
        const response = responseHandler(await requestHandler(request, requestBody,uniqueUri));
        return response;
    };

    GavelBaseStructure.get = async (request,_,uniqueUri = false) => {
        const requestBody = {};
        requestBody.method = 'GET';
        requestBody.credentials = 'same-origin';
        requestBody.headers = {
            'Content-Type': 'application/json',
            'access-control-allow-origin': '*',
        };
        const response = responseHandler(await requestHandler(request, requestBody,uniqueUri));
        return response;
    };

    const requestHandler = async (request, data,uniqueUri) => {
        let requestURI = ''
        if(uniqueUri) requestURI = request
        if(!uniqueUri) requestURI = config.apiBaseUrl + request
        try {
            const response = await fetch(requestURI, data);
            const result = await response.text();
            if (JSON.parse(result)) {
                const data = JSON.parse(result);
                return data;
            } else {
                console.log(result);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const responseHandler = (data = {}, params = {}) => {
        const returnHandler = {};
        returnHandler.on = (type, callable) => {
            if (type === 'static') return callable(data);
        };
        return returnHandler;
    };
    return GavelBaseStructure;
};

export default GavelBaseInstance;
