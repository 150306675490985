import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import ReportImage from '@assets/img/docs.png';
import GavelBaseInit, { config } from '@components/GavelBase';
import Preloader from '@components/Preloader';
import { Link } from "react-router-dom";
import PagesHeader from '@components/PagesHeader';



export default function Reports() {
    const [reports, setReports] = useState();
    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('fetch/reports');
            getter.on('static', (data) => setReports(data.Details));
        })();
    }, []);

    return (
        <>
            {reports ? (
                <MainContainer>
                    <main class="main">
                        <PagesHeader backgroundImage={ReportImage} preTitle="Download" title="Report" />
                        <section class="section causes-inner">
                            <div class="container">
                                <div class="row offset-margin">
                                    {reports.map((data, index) => {
                                        return (
                                            <div class="col-lg-4" key={index}>
                                                <div class="causes-item causes-item--style-2">
                                                    <div class="causes-item__body">
                                                        <div class="causes-item__action">
                                                            <div
                                                                class="causes-item__badge"
                                                                style={{ backgroundColor: '#ff530f' }}
                                                            >
                                                                {data.ReportFileFormat}
                                                            </div>
                                                            <a href={config.apiUrl + data.ReportFileLink} target="_blank" className="causes-item__link" download>
                                                                Download
                                                            </a>
                                                        </div>
                                                        <div class="causes-item__top">
                                                            <h6 class="causes-item__title">
                                                                {data.ReportTitle}
                                                            </h6>
                                                            <p>
                                                                {data.ReportExcerpt}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
