import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-extended/lib/bootstrap-extended.css'
import './css/styles.css'
import './css/bubbles.css'
import './css/normalized.css'
import Routes from './Routes'

export default function App(){
  return <Routes />
}
