import React from 'react';
import styled from 'rn-css';
import InputCard from './Card';

const Container = styled.View`
    position: relative;
    margin-bottom: 30px;
    width: 100%;
`;
const Label = styled.Text`
    color: #5e5873;
    margin-bottom: 10px;
`;

const Importants = styled.Text`
    margin: 2px;
    color: #ff6600;
    font-weight: bold;
`;

const Input = styled.TextInput`
    width: 100%;
    padding: 10px;
    color: #5e5873;
    border-radius: 6px;
    height: 50px;
`;

export default function TextInputs({ title, ...props }) {
    return (
        <Container>
            <Label>
                {title} <Importants>*</Importants>
            </Label>
            <InputCard>
                <Input {...props} />
            </InputCard>
        </Container>
    );
}
