import React from 'react';
import styled from 'rn-css';
import { config } from '@components/GavelBase';
import LinkButton from '@components/LinkButton';

const Container = styled.ImageBackground`
    width: 100%;
    background-size: cover;
    height: 100vh;
    z-index: 90;
`;

const ContainerWrapper = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 9000;
`;

const CardTitle = styled.Text`
    font-size: 60px;
    line-height: 100px;
    color: #fff;
    letter-spacing: -0.05em;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 50px;
    }
`;

const CardDesc = styled.Text`
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 30px;
    }
`;

const DiscoverButton = styled(LinkButton)`
    margin-top: 15px;
`;

const DiscoverButtonText = styled.Text`
    color: #fff;
`;

export default function Type2({ data }) {
    return (
        <div class="img-gradient">
            <Container source={config.apiUrl + data.SlideImageLink}>
                <ContainerWrapper className="container">
                    <div className="align-container" style={{ marginTop: -80, zIndex: 9000 }}>
                        <div className="align-container__item text-center">
                            <div>
                                <CardTitle>{data.SlideTitle}</CardTitle>
                            </div>
                            <div>
                                <CardDesc>{data.Description}</CardDesc>
                            </div>
                            <DiscoverButton className="button promo-slider__button button--primary">
                                <DiscoverButtonText>{data.ButtonText}</DiscoverButtonText>
                            </DiscoverButton>
                        </div>
                    </div>
                </ContainerWrapper>
            </Container>
        </div>
    );
}
