import React, { useState, useEffect } from 'react';
import MainContainer from '@components/MainContainer';
import About from '@assets/img/about.png';
import Logo from '@assets/img/logo-about.png';
import PagesHeader from '@components/PagesHeader';
import CauseListImage from '@assets/img/causelist.png';

export default function Abouts() {
    return (
        <>
            <MainContainer>
                <main class="main">
                    <PagesHeader backgroundImage={CauseListImage} preTitle="--" title="Privacy Policy" />
                    <section class="section about-us">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-xl-5">
                                    <div class="img-box">
                                        <div class="img-box__img">
                                            <img class="img--bg" src={Logo} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-xl-6 offset-xl-1">
                                    <div class="heading heading--primary">
                                        <span class="heading__pre-title"></span>
                                        <h2 class="heading__title">
                                            <span>Privacy Policy for Podus AI</span>{' '}
                                        </h2>
                                    </div>
                                    <p>Effective Date:25th May, 2024</p>
                                    <br />
                                    <p>
                                        Citizens Gavel (Podus AI) is committed to protecting and respecting your
                                        privacy. This Privacy Policy outlines the types of personal data we collect, how
                                        we use it, and your rights regarding your data under the General Data Protection
                                        Regulation or Nigeria Data Protection Act. (GDPR).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="section about-us about-us--style-2 no-padding-top">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <h4 class="about-us__subtitle">Data We Collect:</h4>
                                    <p>We collect the following personal data from our users:</p>
                                    <ul style={{ marginLeft: 30 }}>
                                        <li>First name</li>
                                        <li>Last name</li>
                                        <li>Email</li>
                                        <li>Phone number</li>
                                        <li>Gender</li>
                                        <li>Queries for product analysis and improvement</li>
                                    </ul>
                                    <br />
                                    <h4 class="about-us__subtitle">How We Use Your Data:</h4>
                                    <ul style={{ marginLeft: 30 }}>
                                        <li>To provide and improve our services</li>
                                        <li>To communicate with you</li>
                                        <li>To analyze and enhance our product features and performance</li>
                                    </ul>
                                    <br />
                                    <h4 class="about-us__subtitle">Legal Basis for Processing:</h4>
                                    <p>
                                        Our legal basis for collecting and processing your personal data is your
                                        consent, which you provide when you use our services and agree to this Privacy
                                        Policy.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Data Retention:</h4>
                                    <p>
                                        We retain your personal data for as long as necessary to fulfill the purposes
                                        for which it was collected, or as required by law
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Your Rights:</h4>
                                    <p>Under the GDPR, you have the following rights:</p>
                                    <ul style={{ marginLeft: 30 }}>
                                        <li>Right to Access: You can request access to your personal data we hold.</li>
                                        <li>
                                            Right to Rectification: You can request the correction of inaccurate or
                                            incomplete data.
                                        </li>
                                        <li>
                                            Right to Erasure: You can request the deletion of your personal data at any
                                            time.
                                        </li>
                                        <li>
                                            Right to Restrict Processing: You can request a restriction of processing
                                            under certain conditions.
                                        </li>
                                        <li>
                                            Right to Data Portability: You can request to receive your data in a
                                            commonly used format.
                                        </li>
                                    </ul>
                                    <br />
                                    <h4 class="about-us__subtitle">How to Exercise Your Rights:</h4>
                                    <p>
                                        To exercise any of your rights, please contact us at hello@gavel.ng. We will
                                        respond to your request within one month.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Data Security:</h4>
                                    <p>
                                        We implement appropriate technical and organisational measures to protect your
                                        personal data against unauthorised access, alteration, disclosure, or
                                        destruction.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Changes to This Privacy Policy :</h4>
                                    <p>
                                        We may update this Privacy Policy from time to time. Any changes will be posted
                                        on this page with an updated effective date.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Contact Us:</h4>
                                    <p>
                                        If you have any questions or concerns about this Privacy Policy, please contact
                                        us at: - Email: hello@gavel.ng.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Terms */}
                    <section class="section about-us">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-xl-5">
                                    <div class="img-box">
                                        <div class="img-box__img">
                                            <img class="img--bg" src={Logo} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-xl-6 offset-xl-1">
                                    <div class="heading heading--primary">
                                        <span class="heading__pre-title"></span>
                                        <h2 class="heading__title">
                                            <span>Terms and Conditions for Citizens Gavel (Podus AI)</span>{' '}
                                        </h2>
                                    </div>
                                    <p>Effective Date:25th May, 2024</p>
                                    <br />
                                    <p>
                                        Welcome to Citizens Gavel (Podus AI. By accessing and using our services, you
                                        agree to comply with and be bound by the following Terms and Conditions. Please
                                        read them carefully.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="section about-us about-us--style-2 no-padding-top">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <h4 class="about-us__subtitle">Use of Services:</h4>
                                    <p>
                                        You agree to use our services only for lawful purposes and in accordance with
                                        these Terms and Conditions.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">User Accounts:</h4>
                                    <p>
                                        To access certain features of our services, you may need to create an account.
                                        You are responsible for maintaining the confidentiality of your account
                                        information and for all activities that occur under your account.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Data Collection and Privacy:</h4>
                                    <p>
                                        By using our services, you consent to the collection and use of your personal
                                        data as outlined in our Privacy Policy.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">User Responsibilities:</h4>
                                    <p>You agree not to:</p>
                                    <ul style={{ marginLeft: 30 }}>
                                        <li>Use our services for any illegal or unauthorised purpose</li>
                                        <li>Interfere with or disrupt the integrity or performance of our services</li>
                                        <li>Attempt to gain unauthorised access to our services or related systems</li>
                                    </ul>
                                    <br />
                                    <h4 class="about-us__subtitle">Intellectual Property:</h4>
                                    <p>
                                        All content and materials provided by Citizens Gavel are protected by
                                        intellectual property laws. You may not reproduce, distribute, or create
                                        derivative works from our content without our prior written permission.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Termination:</h4>
                                    <p>
                                        We reserve the right to terminate or suspend your access to our services at any
                                        time, without notice, for conduct that we believe violates these Terms and
                                        Conditions or is harmful to other users of our services.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Data Security:</h4>
                                    <p>
                                        We implement appropriate technical and organisational measures to protect your
                                        personal data against unauthorised access, alteration, disclosure, or
                                        destruction.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Limitation of Liability:</h4>
                                    <p>
                                        Citizens Gavel is not liable for any direct, indirect, incidental,
                                        consequential, or punitive damages arising out of your use of our services.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Governing Law:</h4>
                                    <p>
                                        These Terms and Conditions are governed by and construed in accordance with the
                                        laws of Nigeria, without regard to its conflict of law principles.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Changes to Terms and Conditions :</h4>
                                    <p>
                                        We may update these Terms and Conditions from time to time. Any changes will be
                                        posted on this page with an updated effective date.
                                    </p>
                                    <br />
                                    <h4 class="about-us__subtitle">Contact Us:</h4>
                                    <p>
                                        If you have any questions about these Terms and Conditions, please contact us
                                        at: Email: hello@gavel.ng.
                                    </p>
                                    <br />
                                    <p>
                                        By using Podus AI, you acknowledge that you have read, understood, and agree to
                                        be bound by these Terms and Conditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </MainContainer>
        </>
    );
}
