import React from 'react';
import { intToString } from '@components/Utils';
import LinkButton from '@components/LinkButton';


export default function Impacts({ data }) {
    const mainData = data[0];
    return (
        <section className="section about--front-2 background--white" id="about">
            {/* <h2 className="container-tag-2">Impact</h2> */}
            <div className="container">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="heading heading--primary">
                            <h2 className="heading__title no-margin-bottom">
                                <span>Our Impact</span>{' '}
                            </h2>
                        </div>
                        <LinkButton to="/about" className="button button--primary d-none d-xl-inline-block">
                            More
                        </LinkButton>
                    </div>
                    <div className="col-xl-8">
                        <div className="row margin-top offset-margin">
                            <div className="col-md-4 text-md-left text-center">
                                <div className="counter-item counter-item--style-3">
                                    <div className="counter-item__top">
                                        <h6 className="counter-item__title">Cases Received</h6>
                                    </div>
                                    <div className="counter-item__lower">
                                        <span className="js-counter">{intToString(mainData.CasesReceived)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-md-left text-center">
                                <div className="counter-item counter-item--style-3">
                                    <div className="counter-item__top">
                                        <h6 className="counter-item__title">Cases Resolved</h6>
                                    </div>
                                    <div className="counter-item__lower">
                                        <span className="js-counter">{intToString(mainData.CasesResolved)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-md-left text-center">
                                <div className="counter-item counter-item--style-3">
                                    <div className="counter-item__top">
                                        <h6 className="counter-item__title">People Reached</h6>
                                    </div>
                                    <div className="counter-item__lower">
                                        <span className="js-counter">{intToString(mainData.PeopleReached)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
