import React from 'react';
import MapCanvas from '@components/MapCanvas';
import LinkButton from '@components/LinkButton';

export default function Ranking({ data }) {
    return (
        <section className="section about-us" id="court-ranking">
            <div className="container container-about">
                <div className="container-tag">
                    <h2>Federal Court Rankings</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <MapCanvas />
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="heading heading--primary">
                            <span className="heading__pre-title" />
                            <h2 className="heading__title">
                                <span>Measuring The Pace Of Justice </span>
                                <span>Through Data</span>
                            </h2>
                        </div>
                        <p>
                            The ranking of State High Courts according to their speed of justice delivery became
                            necessary in the absence of data to benchmark the performance of High Courts of States. It
                            is difficult to change what cannot be measured. Hence, to improve justice delivery in
                            Nigeria, stakeholders need adequate data to make informed decisions.
                        </p>
                        <LinkButton to="/publication" className="button button--primary">
                            More
                        </LinkButton>
                    </div>
                </div>
            </div>
        </section>
    );
}
