import React from 'react';
import { config } from '@components/GavelBase';

export default function AboutUs({ data }) {
    return (
        <>
            <section className="section causes">
                <div className="container">
                    <div className="container-tag">
                        <h2>Tech For Justice</h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="heading heading--primary">
                                <span className="heading__pre-title">About Us</span>
                                <h2 className="heading__title no-margin-bottom">
                                    <span>What We Do</span>
                                </h2>
                                <h2>
                                    <span id="sub-title">Access To Justice</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <p>
                                Gavel is a not for profit/non-governmental organization with the aim of increasing the
                                pace of justice delivery through access to justice, digital technology and citizens’
                                engagement.
                            </p>
                            <div className="row margin-top offset-margin">
                                <div className="col-md-6 text-md-left text-center" />
                                <div className="col-md-6 text-md-left text-center" style={{ marginBottom: 20 }}>
                                    {/* <div className="slider__nav causes-slider__nav">
                                        <div className="slider__arrows ">
                                            <div className="slider__prev ">
                                                <i className="fa fa-chevron-left " aria-hidden="true "></i>
                                            </div>
                                            <div className="slider__next ">
                                                <i className="fa fa-chevron-right " aria-hidden="true "></i>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center" />
                    </div>
                    <div className="row">
                        {data &&
                            data.map((data, index) => {
                                return (
                                    <div className="col-md-6 col-lg-4" key={index}>
                                        <div className="causes-slider__item">
                                            <div className="causes-item causes-item--primary">
                                                <div className="causes-item__body">
                                                    <div className="causes-item__top">
                                                        <h6 className="causes-item__title">
                                                            <a href="atj.html">{data.ProjectTitle}</a>
                                                        </h6>
                                                        <p>{data.ProjectIntro}</p>
                                                    </div>
                                                    <div className="causes-item__img">
                                                        <div
                                                            className="causes-item__badge"
                                                            style={{ backgroundColor: '#FA6017' }}
                                                        >
                                                            {data.ProjectTitle}
                                                        </div>
                                                        <img
                                                            className="img--bg"
                                                            src={config.apiUrl + data.ProjectImage}
                                                            alt="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>
            {/* <Seperator /> */}
        </>
    );
}
