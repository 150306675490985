import React, { useState } from 'react';
import './index.css';
import FsLightbox from 'fslightbox-react';
import LinkButton from '@components/LinkButton';

export default function GalleryCard({ CategoryName, link = false, image, videoID }) {
    const [toggler, setToggler] = useState(false);
    return (
        <div class="col-md-6 col-lg-4 ">
            {link ? (
                <div className="team-item team-item--rounded">
                    <LinkButton to={`/gallery/${link}`} style={{height: '100%', width: '100%'}}>
                        <div className="team-item__img-holder">
                            <div className="team-item__img">
                                <img className="img--bg" src={image} alt="teammate" />
                            </div>
                        </div>
                        <div className="team-item__description">
                            <div className="team-item__name">{CategoryName}</div>
                        </div>
                    </LinkButton>
                </div>
            ) : (
                <div
                    className="team-item team-item--rounded"
                    onClick={() => {
                        setToggler(!toggler);
                    }}
                >
                    <div className="team-item__img-holder">
                        <div className="team-item__img">
                            <img className="img--bg" src={image} alt="teammate" />
                        </div>
                    </div>
                    <div className="team-item__description">
                        <div className="team-item__name">{CategoryName}</div>
                    </div>
                </div>
            )}
            <FsLightbox toggler={toggler} sources={[`https://www.youtube.com/watch?v=${videoID}`]} />
        </div>
    );
}
