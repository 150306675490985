import React, { useEffect, useRef, useState } from 'react';
import Type1 from './Cards/Type1';
import Type2 from './Cards/Type2';
import Slider from 'react-slick';
import Modal from '@components/Modal';
import { config } from '@components/GavelBase';
import SlideThumb from '@assets/img/img-bg.jpg';
import styled from 'styled-components';

// import Youtube from './Cards/Youtube';

const YoutubeContainer = styled.div`
    height: calc(100vh - 100px);
    width: 100%;
    border-radius: 10px;
    position: fixed;
    top: 84px;
    left: 0;
`;

const YoutubeContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const YoutubeWrapper = styled.div`
    height: 95%;
    width: 50%;
    position: relative !important;
    @media only screen and (max-width: 767px) {
        height: 95%;
        width: 90%;
    }
`;

const YoutubePlayer = styled.iframe`
    height: 100%;
    width: 100%;
    position: relative !important;
    left: 0px !important;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
`;

export default function Header({ data, settingData }) {
    const [showVideo, setShowVideo] = useState(false);
    const sliderRef = useRef(null);
    const [sliderLists, setSliderLists] = useState([<Type1 key={1} />]);

    //  useEffect(() => {
    //     for(let i = 0; i < data.length; i++){
    //         if(data[i].SlidePosition === "promo-slider__wrapper-1") {
    //             sliderLists.push(<Type1 key={1} data={data[i]} />)
    //             setSliderLists([...sliderLists])
    //         }
    //         if(data[i].SlidePosition === "promo-slider__wrapper-2") {
    //             sliderLists.push(<Type2 key={2} data={data[i]} />)
    //             setSliderLists([...sliderLists])
    //         }
    //     }
    //  },[])

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
    };
    return (
        <>
            {sliderLists.length && (
                <Slider {...settings} ref={sliderRef}>
                    {sliderLists.map((children, index) => children)}
                </Slider>
            )}
            <ul class="promo-socials">
                <li class="aside-socials__item">
                    <a class="aside-socials__link" href="https://www.instagram.com/citizens_gavel/">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="aside-socials__item">
                    <a class="aside-socials__link aside-socials__link--active" href="https://twitter.com/citizen_gavel">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="aside-socials__item">
                    <a class="aside-socials__link" href="https://web.facebook.com/CitizenGavel">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>

            <div class="promo-pannel">
                <a class="anchor promo-pannel__anchor" href="#about">
                    <span>Scroll Down</span>
                </a>
                <div class="promo-pannel__video">
                    <img class="img--bg1" src={SlideThumb} alt="image" />
                    <button type="button" class="button3 button--primary video-btn" onClick={() => setShowVideo(true)}>
                        Watch our video
                    </button>
                </div>

                <div class="promo-pannel__phones">
                    <p class="promo-pannel__title">Phone numbers</p>
                    <a class="promo-pannel__link" href="tel:08102842542">
                        0810 284 2542
                    </a>
                </div>
                <div class="promo-pannel__email">
                    <p class="promo-pannel__title">Email</p>
                    <a class="promo-pannel__link" href="mailto:hello@gavel.ng">
                        hello@gavel.ng
                    </a>
                </div>
            </div>

            <div class="slider__nav slider__nav--promo">
                <div class="slider__arrows">
                    <div class="slider__prev" onClick={() => sliderRef.current.slickPrev()}>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                    <div class="slider__next" onClick={() => sliderRef.current.slickNext()}>
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <Modal
                visible={showVideo}
                onRequestClose={function () {
                    setShowVideo(false);
                }}
                onBackdropPress={function () {
                    setShowVideo(false);
                }}
            >
                <div class="modal show" tabindex="-1" style={{ display: 'block' }}>
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <YoutubeContainer>
                                        <YoutubeContentWrapper>
                                            <YoutubeWrapper>
                                                <YoutubePlayer src="https://www.youtube.com/embed/AKCqMKVIJSo" />
                                                <CloseButton onClick={() => setShowVideo(false)}>Close</CloseButton>
                                            </YoutubeWrapper>
                                        </YoutubeContentWrapper>
                                    </YoutubeContainer>

                                    {/* <Youtube uri="https://www.youtube.com/embed/AKCqMKVIJSo" /> */}
                                    {/* <Youtube uri="https://www.youtube.com/watch?v=AKCqMKVIJSo" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="promo-pannel-container-2">
                <div class="promo-pannel__phones" style={{ display: 'block' }}>
                    <p class="promo-pannel__title">Phone numbers</p>
                    <a class="promo-pannel__link" href="tel:08102842542">
                        0810 284 2542
                    </a>
                </div>
                <div class="promo-pannel__email" style={{ display: 'block' }}>
                    <p class="promo-pannel__title">Email</p>
                    <a class="promo-pannel__link" href="mailto:hello@gavel.ng">
                        hello@gavel.ng
                    </a>
                </div>
            </div>
        </>
    );
}
