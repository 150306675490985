import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@components/Modal';
import styled from 'styled-components';

const DefamationDetailsContainer = styled.div`
    height: calc(100vh - 100px);
    width: 100%;
    border-radius: 10px;
    position: fixed;
    top: 84px;
    left: 0;
`;

const DefamationDetailsContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const DefamationDetailsWrapper = styled.div`
    height: 95%;
    width: 50%;
    position: relative !important;
    @media only screen and (max-width: 767px) {
        height: 95%;
        width: 90%;
    }
`;

const DefamationDetailsPlayer = styled.div`
    height: 100%;
    width: 100%;
    position: relative !important;
    left: 0px !important;
    background-color: #fff;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
`;

const DetailsHeader = styled.div`
    height: 50px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    margin-left: 10px;
`;

const DetailsBody = styled.div`
    padding: 10px;
    height: calc(100% - 50px);
    overflow-y: auto;
`;

export default function InfographicsCard({ title, Image, excerpt }) {
    const [showVideo, setShowVideo] = useState(false);

    return (
        <>
            <div class="col-md-6 col-lg-4">
                <div class="causes-item causes-item--primary">
                    <div class="causes-item__body">
                        <div class="causes-item__top">
                            <h6 class="causes-item__title">{title}</h6>
                        </div>
                        <div class="causes-item__img">
                            <img class="img--bg" src={Image} alt="img" />
                        </div>
                        <button onClick={() => setShowVideo(true)}>
                            <div class="button causes-item__button button--primary">View More</div>
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                visible={showVideo}
                onRequestClose={function () {
                    setShowVideo(false);
                }}
                onBackdropPress={function () {
                    setShowVideo(false);
                }}
            >
                <div class="modal show" tabindex="-1" style={{ display: 'block' }}>
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <DefamationDetailsContainer>
                                        <DefamationDetailsContentWrapper>
                                            <DefamationDetailsWrapper>
                                                <DefamationDetailsPlayer>
                                                    <DetailsHeader>
                                                        <Title>{title}</Title>
                                                    </DetailsHeader>
                                                    <DetailsBody>{excerpt}</DetailsBody>
                                                </DefamationDetailsPlayer>
                                                <CloseButton onClick={() => setShowVideo(false)}>Close</CloseButton>
                                            </DefamationDetailsWrapper>
                                        </DefamationDetailsContentWrapper>
                                    </DefamationDetailsContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
