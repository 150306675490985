import React from 'react';
import Slider from 'react-slick';
import { config } from '@components/GavelBase';

export default function Donors({ data }) {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
        ],
    };

    return (
        <section class="section donors donors--style-2 ">
            <div class="container ">
                <h2 class="container-tag-2">Donors</h2>
                <div class="row margin-bottom ">
                    <div class="col-12 ">
                        <div class="heading heading--primary heading--center ">
                            <h2 class="heading__title no-margin-bottom ">
                                <span>Our </span> <span>Sponsors</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <Slider {...settings}>
                    {data &&
                        data?.map((donors, index) => {
                            return (
                                <div class="donors-slider__item" key={index} style={{ margin: 15 }}>
                                    <div class="donors-slider__img ">
                                        <img src={config.apiUrl + donors.LogoOfSponsor} alt="donor" />
                                    </div>
                                </div>
                            );
                        })}
                </Slider>
            </div>
        </section>
    );
}
