import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import About from './pages/About';
import Infographics from './pages/Infographics';
import Causelist from './pages/Causelist';
import Finances from './pages/Finances';
import Reports from './pages/Reports';
import Publication from './pages/Publication';
import Stories from './pages/Stories';
import StoryView from './pages/StoryView';
import CourtOrderTracker from './pages/CourtOrderTracker';
import Volunteer from './pages/Volunteer';
import ContactUs from './pages/ContactUs';
import Gallery from './pages/Gallery';
import GalleryView from './pages/GalleryView';
import LoanAppDefamation from './pages/LoanAppDefamation';
import Privacy from './pages/Privacy';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/infographics" component={Infographics} />
                <Route exact path="/loan-app-defamation" component={LoanAppDefamation} />
                <Route exact path="/about" component={About} />
                <Route exact path="/causelist" component={Causelist} />
                <Route exact path="/finances" component={Finances} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/publication" component={Publication} />
                <Route exact path="/stories" component={Stories} />
                <Route exact path="/story/:storyID/:storyTitle" component={StoryView} />
                <Route exact path="/court-order-tracker" component={CourtOrderTracker} />
                <Route exact path="/volunteer" component={Volunteer} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/gallery/:galleryID" component={GalleryView} />
                <Route exact path="/privacypolicy" component={Privacy} />
            </Switch>
        </BrowserRouter>
    );
}
