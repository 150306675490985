import React from 'react';
import styled from 'rn-css';

const Container = styled.ImageBackground`
    width: 100%;
    background-size: cover;
    height: 100vh;
    z-index: 9000;
`;

const ContainerWrapper = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 9000;
`;

export default function PagesHeader({ backgroundImage, preTitle, title, fileUri = false }) {
    function MainWrapper({ children }) {
        return (
            <>
                {fileUri ? (
                    <Container source={backgroundImage}>{children}</Container>
                ) : (
                    <Container source={{ uri: backgroundImage }}>{children}</Container>
                )}
            </>
        );
    }

    return (
        <div class="promo-primary img-gradient">
            <MainWrapper>
                <ContainerWrapper>
                    <div className="container">
                        <div className="align-container" style={{ marginTop: -80, zIndex: 9000 }}>
                            <div className="align-container__item">
                                <span class="promo-primary__pre-title">{preTitle}</span>
                                <h1 class="promo-primary__title">
                                    <span>{title}</span>
                                    <br />
                                </h1>
                            </div>
                        </div>
                    </div>
                </ContainerWrapper>
            </MainWrapper>
        </div>
    );
}
