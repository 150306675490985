import React from 'react'
import Image from '@assets/img/counter.png'
import LinkButton from '@components/LinkButton';


export default function Volunteer(){
    return (
        <section className="section action-section ">
        <div className="jarallax ">
            <img className="img--bg" src={Image} alt="img" />
        </div>
        <div className="container" style={{position: "relative", zIndex: 3000, padding: 20}}>
            <div className="row ">
                <div className="col-12 text-center ">
                    <h2 className="action-section__title ">Join Our Impactful Volounteer Team</h2>
                    <p className="action-section__text ">
                    </p>
                    <LinkButton to="/volunteer" className="action-section__button button button--primary">
                        Become a volounteer
                    </LinkButton>
                </div>
            </div>
        </div>
    </section>
    )
}
