import React, { useState, useEffect } from 'react';
import LinkButton from '../LinkButton';
import { useLocation } from 'react-router-dom';
import Logo from '@assets/img/logo.png';
import './index.css';

export default function Header({ fixHeader }) {
    const location = useLocation();
    const [mobileNav, setMobileNav] = useState(false);
    const [page, setPage] = useState('/');
    useEffect(() => {
        setPage(location.pathname);
    }, [location]);

    function mobileNavHandler() {
        setMobileNav(!mobileNav);
        if (!mobileNav) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }

    return (
        <>
            <div class={`aside-dropdown ${mobileNav && 'aside-dropdown--active'}`} onClick={mobileNavHandler}>
                <div class="aside-dropdown__inner">
                    <span class="aside-dropdown__close"></span>

                    <div class="aside-dropdown__item">
                        <ul class="aside-menu">
                            <li class="aside-menu__item">
                                <LinkButton to="/" className="aside-menu__link">
                                    <span>Home</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/about" className="aside-menu__link">
                                    <span>About</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/infographics" className="aside-menu__link">
                                    <span>Infographics</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/finances" className="aside-menu__link">
                                    <span>Finances</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/causelist" className="aside-menu__link">
                                    <span>Causelist</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/publication" className="aside-menu__link">
                                    <span>Publication</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/loan-app-defamation" className="aside-menu__link">
                                    <span>Loan App Defamation</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/reports" className="aside-menu__link">
                                    <span>Report</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/stories" className="aside-menu__link">
                                    <span>Stories</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/court-order-tracker" className="aside-menu__link">
                                    <span>Court Order Tracker</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/contact-us" className="aside-menu__link">
                                    <span>Contact</span>
                                </LinkButton>
                            </li>
                            <li class="aside-menu__item">
                                <a class="aside-menu__link" target="_" href="https://blog.gavel.ng/">
                                    Blogs
                                </a>
                            </li>
                            <li class="aside-menu__item">
                                <LinkButton to="/volunteer" className="aside-menu__link">
                                    <span>Volunteers</span>
                                </LinkButton>
                            </li>
                        </ul>
                        <div class="aside-inner">
                            <span class="aside-inner__title">Email</span>
                            <a class="aside-inner__link" href="mailto:hello@gavel.ng">
                                hello@gavel.ng
                            </a>
                        </div>
                        <div class="aside-inner">
                            <span class="aside-inner__title">Phone numbers</span>
                            <a class="aside-inner__link" href="tel:+234 810 284 2542">
                                +234 810 284 2542
                            </a>
                        </div>
                    </div>
                    <div class="aside-dropdown__item">
                        <a class="button button--squared" href="https://rave.flutterwave.com/donate/uxkfuzymmpkj ">
                            <span>Donate</span>
                        </a>
                    </div>
                </div>
            </div>
            <header className={`header header--front_2 ${fixHeader && 'fixed'}`}>
                <div className="header-container">
                    <div className="container">
                        <div className="row no-gutters justify-content-between">
                            <div className="col-auto d-flex align-items-center">
                                <div className="header-logo">
                                    <LinkButton to="/" className="header-logo__link">
                                        <img className="header-logo__img" src={Logo} alt="logo" />
                                    </LinkButton>
                                </div>
                            </div>
                            <div className="col-auto">
                                <nav>
                                    <ul className="main-menu">
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                page === '/' && 'main-menu__item--active'
                                            }`}
                                        >
                                            <LinkButton to="/" className="main-menu__link">
                                                <span>Home</span>
                                            </LinkButton>
                                        </li>
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                page === '/about' && 'main-menu__item--active'
                                            }`}
                                        >
                                            <LinkButton to="/about" className="main-menu__link">
                                                <span>About</span>
                                            </LinkButton>
                                        </li>
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                page === '/infographics' && 'main-menu__item--active'
                                            }`}
                                        >
                                            <LinkButton to="/infographics" className="main-menu__link">
                                                <span>Infographics</span>
                                            </LinkButton>
                                        </li>
                                        {/* <li
                                        className={`main-menu__item main-menu__item--has-child ${
                                            page === '/gallery' && 'main-menu__item--active'
                                        }`}
                                    >
                                        <LinkButton to="/gallery" className="main-menu__link">
                                            <span>Gallery</span>
                                        </LinkButton>
                                    </li> */}
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                (page === '/causelist' ||
                                                    page === '/reports' ||
                                                    page === '/publication') &&
                                                'main-menu__item--active'
                                            }`}
                                        >
                                            <a className="main-menu__link" href="javascript:void(0);">
                                                <span>Documents</span>
                                            </a>
                                            <ul className="main-menu__sub-list">
                                                <li>
                                                    <LinkButton to="/causelist">
                                                        <span>Causelist</span>
                                                    </LinkButton>
                                                </li>
                                                <li>
                                                    <LinkButton to="/finances">
                                                        <span>Finances</span>
                                                    </LinkButton>
                                                </li>
                                                <li>
                                                    <LinkButton to="/reports">
                                                        <span>Report</span>
                                                    </LinkButton>
                                                </li>
                                                <li>
                                                    <LinkButton to="/publication">
                                                        <span>Publication</span>
                                                    </LinkButton>
                                                </li>
                                                <li>
                                                    <LinkButton to="/loan-app-defamation">
                                                        <span>Loan App Defamation</span>
                                                    </LinkButton>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                (page === '/stories' ||
                                                    page === '/court-order-tracker' ||
                                                    page === '/volunteer') &&
                                                'main-menu__item--active'
                                            }`}
                                        >
                                            <a className="main-menu__link" href="javascript:void(0);">
                                                <span>Activities</span>
                                            </a>
                                            <ul className="main-menu__sub-list">
                                                <li>
                                                    <LinkButton to="/stories">
                                                        <span>Stories</span>
                                                    </LinkButton>
                                                </li>
                                                {/* <li>
                                                <LinkButton to="/court-order-tracker">
                                                    <span>Court Order Tracker</span>
                                                </LinkButton>
                                            </li> */}
                                                <li>
                                                    <LinkButton to="/volunteer">
                                                        <span>Volunteer</span>
                                                    </LinkButton>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            className={`main-menu__item main-menu__item--has-child ${
                                                page === '/contact-us' && 'main-menu__item--active'
                                            }`}
                                        >
                                            <LinkButton to="/contact-us" className="main-menu__link">
                                                <span>Contacts</span>
                                            </LinkButton>
                                        </li>
                                        <li className="main-menu__item main-menu__item--has-child">
                                            <a
                                                className="main-menu__link"
                                                target="_"
                                                href="https://medium.com/@hello_98724"
                                            >
                                                <span>Blog</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <a
                                    className="button button--squared"
                                    href="https://rave.flutterwave.com/donate/uxkfuzymmpkj "
                                >
                                    <span>Donate</span>
                                </a>
                                <div className="dropdown-trigger d-block d-sm-none" onClick={mobileNavHandler}>
                                    <div className="dropdown-trigger__item" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
