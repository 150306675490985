import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
    border: 1px solid #f0f7fc;
`;

export default function Cards({ children, nopadding, style }) {
    return (
        <Container nopadding={nopadding} style={style}>
            {children}
        </Container>
    );
}
