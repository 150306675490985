import React from 'react';
import styled from 'rn-css';
import { config } from '@components/GavelBase';
import LinkButton from '@components/LinkButton';

const Container = styled.ImageBackground`
    width: 100%;
    background-size: cover;
    height: 100vh;
    z-index: 90;
`;

const ContainerWrapper = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 9000;
`;

const CardTitle = styled.Text`
    font-size: 60px;
    line-height: 100px;
    color: #fff;
    letter-spacing: -0.05em;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 50px;
    }
`;

const CardDesc = styled.Text`
    font-family: Storytella, sans-serif;
    color: rgb(240, 93, 36);
    font-weight: 700;
    letter-spacing: 0.05em;
    text-align: center;
    font-size: 70px;
    line-height: 100px;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 70px;
    }
`;

const DiscoverButton = styled(LinkButton)``;

const DiscoverButtonText = styled.Text`
    color: #fff;
`;
export default function Type1({ data }) {
    return (
        <div class="img-gradient">
            <Container source={require('@assets/img/slider_1.png')}>
                <ContainerWrapper>
                    <div className="container">
                        <div className="align-container" style={{ marginTop: -80, zIndex: 9000 }}>
                            <div className="align-container__item text-center">
                                <div>
                                    <CardTitle>We help people get</CardTitle>
                                </div>
                                <div>
                                    <CardDesc>Justice On Time.</CardDesc>
                                </div>
                                <div style={{ color: '#fff', marginBottom: 15 }}>
                                    Citizens Gavel Foundation for Social Justice
                                </div>
                                <DiscoverButton className="button promo-slider__button button--primary" to="/about">
                                    <DiscoverButtonText>Discover</DiscoverButtonText>
                                </DiscoverButton>
                            </div>
                        </div>
                    </div>
                </ContainerWrapper>
            </Container>
        </div>
    );
}
