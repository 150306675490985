import React from 'react';
import Slider from 'react-slick';

export default function Testimonials({ data }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <section class="section testimonials--style-2 ">
            <div class="container ">
                <div className="container-tag">
                    <h2>Testimonials</h2>
                </div>

                <div class="row ">
                    <div class="col-xl-4 ">
                        <div class="heading heading--primary ">
                            <span class="heading__pre-title ">Testimonials</span>
                            <h2 class="heading__title ">
                                <span>What People</span>
                                <br />
                                <span>Says About Us</span>
                            </h2>
                        </div>
                        <div class="slider__nav testimonials-style-2__nav ">
                            <div class="slider__arrows ">
                                <div class="slider__prev ">
                                    <i class="fa fa-chevron-left " aria-hidden="true "></i>
                                </div>
                                <div class="slider__next ">
                                    <i class="fa fa-chevron-right " aria-hidden="true "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 ">
                        <div class="testimonials-slider testimonials-slider--style-2 ">
                            {data && (
                                <Slider {...settings}>
                                    {data?.map((testi, index) => {
                                        return (
                                            <div class="testimonials-slider__item" key={index}>
                                                <div class="testimonials-slider__icon ">“</div>
                                                <div class="testimonials-slider__text ">
                                                    <p>{testi.Testimony}</p>
                                                    <div class="testimonials-slider__author ">
                                                        <span class="testimonials-slider__name ">
                                                            {testi.ReviewedBy}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
