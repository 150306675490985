import React from 'react';
import './index.css';
import Logo from '@assets/img/logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer ">
            <div className="container ">
                <div className="row ">
                    <div className="col-sm-6 col-lg-3 ">
                        <div className="footer-logo ">
                            <a className="footer-logo__link " href="index.html ">
                                <img className="footer-logo__img " src={Logo} alt="logo " />
                            </a>
                        </div>
                        <ul className="footer-socials ">
                            <li className="footer-socials__item ">
                                <a className="footer-socials__link" href="https://web.facebook.com/CitizenGavel ">
                                    <i className="fa fa-facebook " aria-hidden="true " />
                                </a>
                            </li>
                            <li className="footer-socials__item ">
                                <a className="footer-socials__link" href="https://twitter.com/citizen_gavel ">
                                    <i className="fa fa-twitter " aria-hidden="true " />
                                </a>
                            </li>
                            <li className="footer-socials__item ">
                                <a className="footer-socials__link " href="https://www.instagram.com/citizens_gavel/ ">
                                    <i className="fa fa-instagram " aria-hidden="true " />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-3 ">
                        <h4 className="footer__title ">Contacts</h4>
                        <div className="footer-contacts ">
                            <p className="footer-contacts__address ">
                                Address 1: No. 5 Atiba street, Bodija, Ibadan <br />
                                <br />
                                Address 2: 42 Montgomery road, Yaba, Lagos.
                                <br />
                            </p>
                            <br />
                            <p className="footer-contacts__phone ">
                                Phone: <a href="tel:08102842542 ">0810 284 2542</a>
                            </p>
                            <p className="footer-contacts__mail ">
                                Email: <a href="mailto:hello@gavel.ng ">hello@gavel.ng</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 ">
                        <h4 className="footer__title ">Menu & Links</h4>
                        <nav>
                            <ul className="footer-menu ">
                                <li className="footer-menu__item footer-menu__item--active ">
                                    <Link className="footer-menu__link " to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/about">
                                        About
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/contact-us">
                                        Contact
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/infographics">
                                        Infographics
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/causelist">
                                        CauseList
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/reports">
                                        Report
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/publication">
                                        Publication
                                    </Link>
                                </li>

                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/stories">
                                        Stories
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <a className="footer-menu__link" target="_" href="https://blog.gavel.ng">
                                        Blog
                                    </a>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/volunteer">
                                        Volunteer
                                    </Link>
                                </li>
                                <li className="footer-menu__item ">
                                    <Link className="footer-menu__link " to="/court-order-tracker">
                                        Court Order Tracker
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-sm-6 col-lg-3 ">
                        <h4 className="footer__title ">Donate</h4>
                        <p>Help Us Get Swift Justice For People</p>
                        <a
                            className="button2 footer__button button--filled "
                            href="https://rave.flutterwave.com/donate/uxkfuzymmpkj"
                        >
                            Donate
                        </a>
                    </div>
                </div>
                <div className="row align-items-baseline ">
                    <div className="col-md-6 ">
                        <p className="footer-copyright ">© 2023 Gavel</p>
                    </div>
                    <div className="col-md-6 ">
                        <div className="footer-privacy ">
                            <a className="footer-privacy__link " href="# ">
                                Privacy Policy
                            </a>
                            <span className="footer-privacy__divider ">|</span>
                            <a className="footer-privacy__link " href="# ">
                                Terms and Conditions
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
