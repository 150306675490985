import React from 'react';
import Image from '@assets/img/newbg.png';

export default function Newsletter() {
    return (
        <section class="section action-section ">
            <div class="jarallax ">
                <img class="img--bg" src={Image} alt="img" />
            </div>
            <div class="container" style={{position: "relative", zIndex: 3000, padding: 20}}>
                <div class="row align-items-end ">
                    <div class="col-lg-4 ">
                        <h2 class="subscribe__title ">Newsletter.</h2>
                    </div>
                    <div class="col-lg-8 ">
                        <form class="subscribe-form " action="javascript:void(0); ">
                            <div>
                                <input
                                    class="subscribe-form__input "
                                    type="email "
                                    name="email "
                                    placeholder="Enter your Full Name "
                                    required="required "
                                />
                                <input
                                    class="subscribe-form__input "
                                    type="email "
                                    name="email "
                                    placeholder="Enter your E-mail "
                                    required="required "
                                />
                            </div>

                            <a class="action-section__button button button--primary " href="#">
                                Subscribe
                            </a>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
