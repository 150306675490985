import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import StoryImage from '@assets/img/stories_bg.png';
import { useParams } from 'react-router-dom';
import GavelBaseInit, { config } from '@components/GavelBase';
import Preloader from '@components/Preloader';

export default function StoryView() {
    const { storyID } = useParams();
    const [stories, setStories] = useState();

    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get(`pages/stories/view/${storyID}`);
            getter.on('static', (data) => {
                setStories(data.Details[0]);
            });
        })();
    }, []);

    return (
        <>
            {stories ? (
                <MainContainer>
                    <main class="main">
                        <section class="promo-primary">
                            <img class="img--bg" src={StoryImage} alt="img" />
                            <div class="container">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="align-container">
                                            <div class="align-container__item">
                                                <span class="promo-primary__pre-title">What We Have Done</span>
                                                <h1 class="promo-primary__title">
                                                    <span>Our</span> <span>Stories</span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="section storie-details">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-8 offset-xl-2">
                                        <div class="storie-details__top">
                                            <div class="storie-details__img">
                                                <img
                                                    class="img--bg"
                                                    src={config.apiUrl + stories.FeaturedImage}
                                                    alt="img"
                                                />
                                            </div>
                                            <h6 class="storie-details__title">{stories.StoryTitle}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: `${stories.StoryDetails}` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
