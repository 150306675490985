import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import GalleryCard from '@components/GalleryCard';
import GavelBaseInit from '@components/GavelBase';
import { useParams } from 'react-router-dom';
import PagesHeader from '@components/PagesHeader';
import Publications from '@assets/img/pub-bg.png';

export default function GalleryView() {
    const [gallery, setGallery] = useState();
    const { galleryID } = useParams();

    useEffect(() => {
        if (galleryID === 'youtube') {
            (async () => {
                const GavelBase = await GavelBaseInit;
                const getter = await GavelBase.get(
                    'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyDhZdxwDuUjuAgTwCwjAEEPxAN61dVe8GA&channelId=UC6dqOqT8Dw9HFh8cGXsUHSw&playlistId=PLzB7zAR7vZktdz8RLsFLhm99wzoywqGLm&part=snippet,id&maxResults=30',
                    {},
                    true,
                );
                getter.on('static', (data) => {
                    console.log(data)
                    setGallery(data)
                });
            })();
        }
    }, []);

    return (
        <MainContainer>
            <main class="main">
                <PagesHeader backgroundImage={Publications} preTitle="Gallery" title="Youbube" />
                <section class="section causes-inner">
                    <section class="section events ">
                        <div class="container ">
                            <div class="row margin-bottom ">
                                <div class="col-12 ">
                                    <div class="heading heading--primary heading--center ">
                                        <h2 class="heading__title ">
                                            <span>{galleryID}</span>
                                        </h2>
                                        <p class="no-margin-bottom ">Some information about the category here...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                {gallery &&
                                    gallery.items.map((galleryData, index) => {
                                        return (
                                            <GalleryCard
                                                key={index}
                                                image={galleryData.snippet.thumbnails.high.url}
                                                CategoryName={galleryData.snippet.title}
                                                videoID={galleryData.snippet.resourceId.videoId}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </MainContainer>
    );
}
