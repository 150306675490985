import React, { useState } from 'react';
import MainContainer from '@components/MainContainer';
import ContactUsImage from '@assets/img/contacts.png';
import TextInputs from '@components/Forms';
import GavelBaseInit from '@components/GavelBase';
import { ActivityIndicator } from 'react-native';
import PagesHeader from '@components/PagesHeader';

export default function ContactUs() {
    const [state, setState] = useState({
        EnqFirst: '',
        EnqLast: '',
        EnqMob: '',
        EnqSubj: '',
        EnqMessage: '',
        EnqMail: '',
    });
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');

    function onTextChangeHandler(key, value) {
        state[key] = value;
        setState({ ...state });
    }

    async function contactus() {
        setLoading(true);
        const GavelBase = await GavelBaseInit;
        const getter = await GavelBase.post('pages/contact', state);
        getter.on('static', (data) => {
            setLoading(false);
            setResponse(data.Message);
        });
    }

    return (
        <MainContainer>
            <main className="main">
                <PagesHeader backgroundImage={ContactUsImage} preTitle="Reach out to us" title="Contacts" />
                <section className="">
                    <section className="section events bg-bubbles">
                        <div className="container">
                            <div className="row offset-margin">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="icon-item">
                                        <div className="icon-item__img"></div>
                                        <div className="icon-item__text">
                                            <p>
                                                No.5, Atiba, Soun Ajagungbade Estate, Aare, Bodija, Ibadan,Oyo State 2nd
                                                Floor, 42 Montgomery road, Yaba, Lagos.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="icon-item">
                                        <div className="icon-item__img"></div>
                                        <div className="icon-item__text">
                                            <p className="footer-contacts__phone">
                                                Phone: <a href="tel:08102842542">0810 284 2542</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="icon-item">
                                        <div className="icon-item__img"></div>
                                        <div className="icon-item__text">
                                            <p className="footer-contacts__mail">
                                                Email: <a href="mailto:hello@gavel.ng">hello@gavel.ng</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="icon-item">
                                        <div className="icon-item__img"></div>
                                        <div className="icon-item__text">
                                            <div className="socials">
                                                <div className="aside-socials__item theme-1 ">
                                                    <a
                                                        className="aside-socials__link2"
                                                        href="https://www.instagram.com/citizens_gavel/"
                                                    >
                                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div className="aside-socials__item">
                                                    <a
                                                        className="aside-socials__link2 aside-socials__link--active"
                                                        href="https://twitter.com/citizen_gavel"
                                                    >
                                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div className="aside-socials__item">
                                                    <a
                                                        className="aside-socials__link2"
                                                        href="https://web.facebook.com/CitizenGavel"
                                                    >
                                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="section contacts">
                            <div className="contacts-wrapper">
                                <div className="container" style={{ marginBottom: 30 }}>
                                    <div className="row justify-content-end">
                                        <div className="col-xl-12">
                                            <form className="form message-form" action="javascript:void(0);">
                                                <h6 className="form__title">Send Message</h6>
                                                <span className="form__text">* The following info is required</span>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <TextInputs
                                                            title="First Name"
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqFirst', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <TextInputs
                                                            title="Last Name"
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqLast', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <TextInputs
                                                            title="Email"
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqMail', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <TextInputs
                                                            title="Phone Number"
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqMob', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <TextInputs
                                                            title="Subject"
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqSubj', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <TextInputs
                                                            title="Message"
                                                            style={{ height: 200 }}
                                                            onChange={(e) =>
                                                                onTextChangeHandler('EnqMessage', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <p>{response}</p>
                                                    <div className="col-12">
                                                        <button
                                                            className="form__submit"
                                                            style={{ display: 'flex' }}
                                                            onClick={contactus}
                                                            disabled={!!loading}
                                                        >
                                                            {loading ? (
                                                                <>
                                                                    <ActivityIndicator color="#F05D24" />
                                                                    <p>Please Wait...</p>
                                                                </>
                                                            ) : (
                                                                <p> Send Message</p>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="contacts-wrapper__map">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1978.2151331130208!2d3.9149557078152553!3d7.417540899469047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103993945e424fa5%3A0x901ef34fd8f5efd6!2sGavel%20Ibadan%20Office!5e0!3m2!1sen!2sng!4v1597509553965!5m2!1sen!2sng"
                                        width="600"
                                        height="450"
                                        frameborder="0"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        aria-hidden="false"
                                        tabindex="0"
                                    ></iframe>
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </main>
        </MainContainer>
    );
}
