import React from 'react';
import { config } from '@components/GavelBase';
import LinkButton from '@components/LinkButton';
import { convertToSlug } from '@components/Utils';

export default function NewBlogs({ data }) {
    return (
        <section class="section events" style={{ backgroundColor: '#ffffff' }}>
            <div class="container ">
                <h2 class="container-tag-2 ">Latest Blogs</h2>
                <div class="row margin-bottom ">
                    <div class="col-12 ">
                        <div class="heading heading--primary heading--center ">
                            <span class="heading__pre-title ">Blogs</span>
                            <h2 class="heading__title ">
                                <span>Our</span> <span>Blogs</span>
                            </h2>
                            <p class="no-margin-bottom ">
                                We are super-thrilled to share our impactful stories and activities with you!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    {data?.map((story, index) => {
                        return (
                            <a href={`${story.BlogLink}`} target="_" class="col-md-6 col-lg-4" key={index}>
                                <div class="event-item ">
                                    <div class="event-item__img ">
                                        <img class="img--bg " src={story.FeaturedImage} alt="img" />
                                    </div>
                                    <div class="event-item__content ">
                                        <h6 class="event-item__title ">
                                            <a href={`${story.BlogLink}`} target="_">
                                                {story.BlogIntro}
                                            </a>
                                        </h6>
                                        <p>{story.BlogExcerpt}</p>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
                <div class="row ">
                    <div class="col-12 text-center ">
                        <a href="https://medium.com/@hello_98724" target="_" className="button button--primary">
                            View all Blogs
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
