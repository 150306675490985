import React, { useState } from 'react';
import MainContainer from '@components/MainContainer';
import VolunteeImage from '@assets/img/volunteer_bg.png';
import TextInputs from '@components/Forms';
import styled from 'styled-components';
import GavelBaseInit from '@components/GavelBase';
import { ActivityIndicator } from 'react-native';
import PagesHeader from '@components/PagesHeader';


const DescriptionBox = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Volunteer() {
    const [state, setState] = useState({
        VolFirst: '',
        VolLast: '',
        VolMob: '',
        VolMail: '',
        VolLoc: '',
    });

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');

    function onTextChangeHandler(key, value) {
        state[key] = value;
        setState({ ...state });
    }

    async function becomeVolunteer() {
        setLoading(true);
        const GavelBase = await GavelBaseInit;
        const getter = await GavelBase.post('pages/volunteer', state);
        getter.on('static', (data) => {
            setLoading(false);
            setResponse(data.Message);
        });
    }

    return (
        <MainContainer>
            <main class="main">
                <PagesHeader backgroundImage={VolunteeImage} preTitle="Join Us" title="Volunteer" />
                <section class="section events bg-bubbles">
                <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    <div class="container">
                        <div class="row margin-bottom">
                            <div class="col-lg-6">
                                <div class="heading heading--primary">
                                    <span class="heading__pre-title">Fill Form and Become Volonteer</span>
                                    <h2 class="heading__title">
                                        <span>Complete</span> <span>the Form</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <DescriptionBox>
                                    <p>
                                        Sea chub demoiselle whalefish zebra lionfish mud cat pelican eel. Minnow snoek
                                        icefish velvet-belly shark, California halibut round stingray northern sea
                                        robin. Southern grayling trout-perch
                                    </p>
                                </DescriptionBox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <form class="form user-form" action="javascript:void(0);">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <TextInputs
                                                title="First Name"
                                                onChange={(e) => onTextChangeHandler('VolFirst', e.target.value)}
                                            />
                                            <TextInputs
                                                title="Last Name"
                                                onChange={(e) => onTextChangeHandler('VolLast', e.target.value)}
                                            />
                                            <TextInputs
                                                title="Email"
                                                onChange={(e) => onTextChangeHandler('VolMail', e.target.value)}
                                            />
                                            <TextInputs
                                                title="Phone Number"
                                                onChange={(e) => onTextChangeHandler('VolMob', e.target.value)}
                                            />
                                            <TextInputs
                                                title="Address"
                                                placeholder="e.g Ibadan"
                                                onChange={(e) => onTextChangeHandler('VolLoc', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <p>{response}</p>
                                    <div class="row">
                                        <div class="col-12">
                                            <button class="form__submit" style={{display: 'flex'}} onClick={becomeVolunteer} disabled={!!loading}>
                                                {loading ? (
                                                    <>
                                                        <ActivityIndicator color="#F05D24" />
                                                        <p>Please Wait...</p>
                                                    </>
                                                ) : (
                                                    <p> Submit</p>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </MainContainer>
    );
}
