import React, { useRef } from 'react';
import Slider from 'react-slick';
import { config } from '@components/GavelBase';

export default function Infographics({ data }) {
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 7000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    return (
        <section className="section testimonials testimonials--style-3 ">
            <div className="container">
                <div className="container-tag">
                    <h2>Infographics</h2>
                </div>
                <div className="row ">
                    <div className="col-xl-4 ">
                        <div className="row align-items-end ">
                            <div className="col-sm-8 col-md-6 col-xl-12 ">
                                <div className="heading heading--primary ">
                                    <span className="heading__pre-title ">Latest Infographics</span>
                                    <h2 className="heading__title ">
                                        <span>Latest</span>
                                        <br />
                                        <span>Infographics</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-6 col-xl-12 ">
                                <div className="slider__nav testimonials-style-3__nav ">
                                    <div className="slider__arrows ">
                                        <div className="slider__prev" onClick={() => sliderRef.current.slickPrev()}>
                                            <i className="fa fa-chevron-left " aria-hidden="true "></i>
                                        </div>
                                        <div className="slider__next" onClick={() => sliderRef.current.slickNext()}>
                                            <i className="fa fa-chevron-right " aria-hidden="true "></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="testimonials__slider-holder">
                            <div className="testimonials-slider testimonials-slider--style-3 ">
                                <Slider {...settings} ref={sliderRef}>
                                    {data &&
                                        data?.map((infog, index) => {
                                            return (
                                                <div
                                                    class="testimonials-slider__item"
                                                    style={{ height: 300, width: 300 }}
                                                    key={index}
                                                >
                                                    <div class="event-item">
                                                        <div class="event-item__img ">
                                                            <img
                                                                class="img--bg "
                                                                src={config.apiUrl + infog.InfoImage}
                                                                alt="img "
                                                            />
                                                        </div>
                                                        <div class="event-item__content ">
                                                            <h6 class="event-item__title ">
                                                                <a
                                                                    href={config.apiUrl + infog.InfoImage}
                                                                    target="_blank"
                                                                    download
                                                                >
                                                                    {infog.InfoTitle}
                                                                </a>
                                                            </h6>
                                                            <a
                                                                class="button causes-item__button button--primary "
                                                                id="info-button"
                                                                href={config.apiUrl + infog.InfoImage}
                                                                target="_blank"
                                                                download
                                                            >
                                                                View
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
