import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import CauseListImage from '@assets/img/causelist.png';
import GavelBaseInit, { config } from '@components/GavelBase';
import Preloader from '@components/Preloader';
import { Link } from 'react-router-dom';
import PagesHeader from '@components/PagesHeader';

export default function Finances() {
    const [causelist, setCauseList] = useState();

    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('fetch/finances');
            getter.on('static', (data) => setCauseList(data.Details));
        })();
    }, []);

    return (
        <>
            {causelist ? (
                <MainContainer>
                    <main className="main">
                        <PagesHeader backgroundImage={CauseListImage} preTitle="Documents" title="Finances" />
                        <section className="section causes-inner">
                            <div className="container">
                                <div className="row offset-margin">
                                    {causelist.map((lists, index) => {
                                        return (
                                            <div className="col-lg-4" key={index}>
                                                <div className="causes-item causes-item--style-2">
                                                    <div className="causes-item__body">
                                                        <div className="causes-item__action">
                                                            <div
                                                                className="causes-item__badge"
                                                                style={{ backgroundColor: '#ff530f' }}
                                                            >
                                                                {lists.FinanceFileFormat}
                                                            </div>
                                                            <a
                                                                href={config.apiUrl + lists.FinanceFileLink}
                                                                target="_blank"
                                                                className="causes-item__link"
                                                                download
                                                            >
                                                                Download
                                                            </a>
                                                        </div>
                                                        <div className="causes-item__top">
                                                            <h6 className="causes-item__title">{lists.FinanceTitle}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
