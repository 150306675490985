import React, { useRef, useState, useEffect } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import styled from 'styled-components';

const Container = styled.div`
    flex: 1;
    height: 100vh;
    width: 100vw;
`;

export default function MainContainer({ children }) {
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);
    const [fixHeader, setFixHeader] = useState(false);

    useEffect(() => {
        document.body.style.overflowY = "auto";
    },[])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current < currentScrollY && goingUp) {
                setGoingUp(false);
            }
            if (prevScrollY.current > currentScrollY && !goingUp) {
                setGoingUp(true);
            }

            if (currentScrollY > 50) {
                setFixHeader(true);
            } else {
                setFixHeader(false);
            }
            prevScrollY.current = currentScrollY;
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [goingUp]);

    return (
        <Container>
            <div class="page-wrapper">
                <Header fixHeader={fixHeader} />
                {children}
                <Footer />
            </div>
        </Container>
    );
}
