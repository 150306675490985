/* eslint-disable no-undef */
import GavelBaseInstance from './GavelBaseInstance';

//Below Snippers is only for development...
const configs = {};
configs.systemKey = '00eebe90543da76457ac81aa8d8598';
configs.server = 'https://gavel.ng/';
configs.uri = 'https://api.gavel.ng/v1/';
configs.apiBaseUrl = 'https://api.gavel.ng/v1/';
configs.apiUrl = 'https://files.gavel.ng/';

// configs.server = 'https://gavel.ng/';
// configs.uri = 'http://localhost/gavel_api/v1/';
// configs.apiBaseUrl = 'http://localhost/gavel_api/v1/';
// configs.apiUrl = 'http://localhost/files.gavel.ng/';

export const config = configs;
const GavelBase = GavelBaseInstance(config);
export default GavelBase;
