import React from 'react';
import { config } from '@components/GavelBase';
import LinkButton from '@components/LinkButton';
import { convertToSlug } from '@components/Utils';

export default function Stories({ data }) {
    return (
        <section class="section events ">
            <div class="container ">
                <h2 class="container-tag-2 ">Stories</h2>
                <div class="row margin-bottom ">
                    <div class="col-12 ">
                        <div class="heading heading--primary heading--center ">
                            <span class="heading__pre-title ">Stories</span>
                            <h2 class="heading__title ">
                                <span>Our</span> <span>Stories</span>
                            </h2>
                            <p class="no-margin-bottom ">
                                We are super-thrilled to share our impactful stories and activities with you!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    {data?.map((story, index) => {
                        return (
                            <a
                                href={`/story/${story.ID}/${convertToSlug(story.StoryTitle)}`}
                                class="col-md-6 col-lg-4"
                                key={index}
                            >
                                <div class="event-item ">
                                    <div class="event-item__img ">
                                        <img class="img--bg " src={config.apiUrl + story.FeaturedImage} alt="img " />
                                    </div>
                                    <div class="event-item__content ">
                                        <h6 class="event-item__title ">
                                            <a href={`/story/${story.ID}/${convertToSlug(story.StoryTitle)}`}>
                                                {story.StoryTitle}
                                            </a>
                                        </h6>
                                        <p>{story.StoryIntro}</p>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
                <div class="row ">
                    <div class="col-12 text-center ">
                        <LinkButton to="/stories" className="button button--primary">
                            View all stories
                        </LinkButton>
                    </div>
                </div>
            </div>
        </section>
    );
}
