import React, { useState, useEffect } from 'react';
import {
    Animated,
    Easing,
    Modal as ReactNativeModal,
    Platform,
    StyleSheet,
    TouchableWithoutFeedback,
} from 'react-native';
import styled from 'rn-css';

const MODAL_ANIM_DURATION = 300;
const MODAL_BACKDROP_OPACITY = 0.71;

const CONTENT_ANIMATION_IN = Platform.select({
    ios: {
        opacity: {
            inputRange: [0, 1],
            outputRange: [0, 1],
        },
        scale: {
            inputRange: [0, 0.5, 1],
            outputRange: [1.2, 1.1, 1],
        },
    },
    android: {
        opacity: {
            inputRange: [0, 0.5, 1],
            outputRange: [0, 1, 1],
        },
        scale: {
            inputRange: [0, 1],
            outputRange: [0.3, 1],
        },
    },
    default: {
        opacity: {
            inputRange: [0, 0.5, 1],
            outputRange: [0, 1, 1],
        },
        scale: {
            inputRange: [0, 1],
            outputRange: [0.3, 1],
        },
    },
});

const CONTENT_ANIMATION_OUT = Platform.select({
    default: {
        opacity: {
            inputRange: [0, 1],
            outputRange: [0, 1],
        },
    },
});

function TransitionalModal(props) {
    const [state, setState] = useState({
        visible: props.visible,
        currentAnimation: 'none',
        isMounted: false,
        animVal: new Animated.Value(0),
    });

    useEffect(() => {
        if (props.visible) show();
        if (!props.visible) hide();
    }, [props.visible]);

    function show() {
        state.visible = true;
        state.currentAnimation = 'in';
        setState({ ...state });
        Animated.timing(state.animVal, {
            easing: Easing.inOut(Easing.quad),
            // Using native driver in the modal makes the content flash
            useNativeDriver: false,
            duration: MODAL_ANIM_DURATION,
            toValue: 1,
        }).start(() => {
            state.currentAnimation = 'none';
            setState({ ...state });
            props.onShow ? props.onShow() : null;
        });
    }

    function hide() {
        state.currentAnimation = 'out';
        setState({ ...state });
        Animated.timing(state.animVal, {
            easing: Easing.inOut(Easing.quad),
            // Using native driver in the modal makes the content flash
            useNativeDriver: false,
            duration: MODAL_ANIM_DURATION,
            toValue: 0,
        }).start(() => {
            state.currentAnimation = 'none';
            state.visible = false;
            setState({ ...state });
            props.onHide ? props.onHide() : null;
        });
    }

    const { children, onBackdropPress, contentStyle, ...otherProps } = props;
    const { currentAnimation, visible } = state;

    const backdropAnimatedStyle = {
        opacity: state.animVal.interpolate({
            inputRange: [0, 1],
            outputRange: [0, MODAL_BACKDROP_OPACITY],
        }),
    };

    const contentAnimatedStyle =
        currentAnimation === 'in'
            ? {
                  opacity: state.animVal.interpolate({
                      inputRange: CONTENT_ANIMATION_IN.opacity.inputRange,
                      outputRange: CONTENT_ANIMATION_IN.opacity.outputRange,
                      extrapolate: 'clamp',
                  }),
                  transform: [
                      {
                          scale: state.animVal.interpolate({
                              inputRange: CONTENT_ANIMATION_IN.scale.inputRange,
                              outputRange: CONTENT_ANIMATION_IN.scale.outputRange,
                              extrapolate: 'clamp',
                          }),
                      },
                  ],
              }
            : {
                  opacity: state.animVal.interpolate({
                      inputRange: CONTENT_ANIMATION_OUT.opacity.inputRange,
                      outputRange: CONTENT_ANIMATION_OUT.opacity.outputRange,
                      extrapolate: 'clamp',
                  }),
              };

    return (
        <ReactNativeModal transparent animationType="none" {...otherProps} visible={visible}>
            <TouchableWithoutFeedback onPress={onBackdropPress}>
                <Animated.View
                    style={[
                        styles.backdrop,
                        backdropAnimatedStyle,
                        { backgroundColor: !props.NoBackDrop ? '#5E5873' : 'transparent' },
                    ]}
                />
            </TouchableWithoutFeedback>

            {visible && (
                <Animated.View
                    style={[styles.content, contentAnimatedStyle]}
                    pointerEvents="box-none"
                    needsOffscreenAlphaCompositing={['in', 'out'].includes(currentAnimation)}
                >
                    <ModalContainer onPress={onBackdropPress}>{children}</ModalContainer>
                </Animated.View>
            )}
        </ReactNativeModal>
    );
}

const ModalContainer = styled.TouchableOpacity`
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#5E5873',
        opacity: 0.71,
    },
    content: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

TransitionalModal.defaultProps = {
    onBackdropPress: () => null,
    onHide: () => null,
    visible: false,
};

export default React.memo(TransitionalModal);
