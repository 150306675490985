import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import jQuery from 'jquery';
import './Vector.js';
import './Nigeria.js';

export default function MapCanvas() {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        jQuery('#vmap').vectorMap({
            map: 'nigeria_ng',
            backgroundColor: '#f2f2f2',
            borderColor: '#fff',
            borderOpacity: 0.25,
            borderWidth: 1,
            color: '#969696',
            enableZoom: true,
            hoverColor: '#FA6017',
            hoverOpacity: null,
            normalizeFunction: 'linear',
            scaleColors: ['#b6d6ff', '#005ace'],
            selectedColor: '#c9dfaf',
            selectedRegions: null,
            showTooltip: true,
            onRegionClick: function (element, code, region) {
                const message = 'You clicked " ' + region + ' " which has the code: ' + code.toUpperCase();
            },
        });
    }, []);

    return (
        <>
            {isMobile ? (
                <div id="vmap" style={{ width: '95vw', height: 400 }} />
            ) : (
                <div id="vmap" style={{ width: 600, height: 400 }} />
            )}
        </>
    );
}
