import React from 'react';
import MainContainer from '@components/MainContainer';
import Publications from '@assets/img/pub-bg.png';
import Youtube from '@assets/img/youtube.png';
import GalleryCard from '@components/GalleryCard';
import './index.css';
import PagesHeader from '@components/PagesHeader';

export default function Gallery() {
    return (
        <MainContainer>
            <main className="main">
                <PagesHeader backgroundImage={Publications} preTitle="Gallery" title="Gallery" />
                <section className="section causes-inner">
                    <section className="section events bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <div className="container ">
                            <div className="row margin-bottom ">
                                <div className="col-12 ">
                                    <div className="heading heading--primary heading--center ">
                                        <h2 className="heading__title ">
                                            <span>Our</span> <span>Galleries</span>
                                        </h2>
                                        <p className="no-margin-bottom ">Some information about our Gallery here...</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <GalleryCard CategoryName="Youtube" link="youtube" image={Youtube} />
                                <GalleryCard CategoryName="Youtube" link="youtube" image={Youtube} />
                                <GalleryCard CategoryName="Youtube" link="youtube" image={Youtube} />
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </MainContainer>
    );
}
