import React, { useEffect, useState } from 'react';
import MainContainer from '@components/MainContainer';
import StoryImage from '@assets/img/stories_bg.png';
import GavelBaseInit, { config } from '@components/GavelBase';
import Preloader from '@components/Preloader';
import LinkButton from '@components/LinkButton';
import {convertToSlug} from '@components/Utils'

export default function CourtOrderTracker() {
    const [stories, setStories] = useState();
    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('pages/stories');
            getter.on('static', (data) => setStories(data.Details));
        })();
    }, []);

    return (
        <>
            {stories ? (
                <MainContainer>
                    <main class="main">
                        <section class="promo-primary">
                            <img class="img--bg" src={StoryImage} alt="img" />
                            <div class="container">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="align-container">
                                            <div class="align-container__item">
                                                <span class="promo-primary__pre-title">Court Order Tracker</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="section stories">
                            <div class="container">
                                <div class="row offset-70">
                                    {stories.map((data, index) => {
                                        if (index % 2 == 0) {
                                            return (
                                                <div class="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                                                    <div class="stories-item">
                                                        <div class="row align-items-center">
                                                            <div class="col-lg-6 col-xl-5">
                                                                <div class="img-box">
                                                                    <div class="img-box__img">
                                                                        <img
                                                                            class="img--bg"
                                                                            src={config.apiUrl + data.FeaturedImage}
                                                                            alt="img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-6 offset-xl-1">
                                                                <div class="heading heading--primary">
                                                                    <span class="heading__pre-title">{index}</span>
                                                                    <h2 class="heading__title">
                                                                        <span>{data.StoryTitle}</span>
                                                                    </h2>
                                                                </div>
                                                                <p>{data.StoryExcerpt}</p>

                                                                <LinkButton
                                                                    class="button stories-item__button button--primary"
                                                                    to={`/story/${data.ID}/${convertToSlug(data.StoryTitle)}`}
                                                                >
                                                                    Read Story
                                                                </LinkButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div class="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                                                    <div class="stories-item">
                                                        <div class="row align-items-center flex-column-reverse flex-lg-row">
                                                            <div class="col-lg-6 col-xl-6">
                                                                <div class="heading heading--primary">
                                                                    <span class="heading__pre-title">{index}</span>
                                                                    <h2 class="heading__title">
                                                                        <span>{data.StoryTitle}</span>
                                                                    </h2>
                                                                </div>
                                                                <p>{data.StoryExcerpt}</p>
                                                                <LinkButton
                                                                    class="button stories-item__button button--primary"
                                                                    to={`/story/${data.ID}/${convertToSlug(data.StoryTitle)}`}
                                                                >
                                                                    Read Story
                                                                </LinkButton>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-5 offset-xl-1">
                                                                <div class="img-box">
                                                                    <div class="img-box__img">
                                                                        <img
                                                                            class="img--bg"
                                                                            src={config.apiUrl + data.FeaturedImage}
                                                                            alt="img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
