import React, { useState, useEffect } from 'react';
import MainContainer from '@components/MainContainer';
import Publications from '@assets/img/pub-bg.png';
import GavelBaseInit, { config } from '@components/GavelBase';
import Preloader from '@components/Preloader';
import { Link } from 'react-router-dom';
import PagesHeader from '@components/PagesHeader';

export default function Publication() {
    const [publication, setPlublication] = useState();

    useEffect(() => {
        (async () => {
            const GavelBase = await GavelBaseInit;
            const getter = await GavelBase.get('fetch/publication');
            getter.on('static', (data) => setPlublication(data.Details));
        })();
    }, []);

    return (
        <>
            {publication ? (
                <MainContainer>
                    <main class="main">
                        <PagesHeader
                            backgroundImage={Publications}
                            preTitle="Documents"
                            title={
                                <>
                                    <span>Our</span> <span>Publications</span>
                                </>
                            }
                        />
                        <section class="section causes-inner">
                            <div class="container">
                                <div class="row offset-margin">
                                    {publication.map((data, index) => {
                                        return (
                                            <div class="col-md-6 col-lg-4" key={index}>
                                                <div class="causes-item causes-item--primary">
                                                    <div class="causes-item__body">
                                                        <div class="causes-item__top">
                                                            <h6 class="causes-item__title">{data.PubTitle}</h6>
                                                            <p>{data.PubExcerpt}</p>
                                                        </div>
                                                        <div class="causes-item__img">
                                                            <div
                                                                class="causes-item__badge"
                                                                style={{ backgroundColor: '#FA6017' }}
                                                            >
                                                                {data.PubFileFormat}
                                                            </div>
                                                            <img
                                                                class="img--bg"
                                                                src={config.apiUrl + data.DisplayImage}
                                                                alt="img"
                                                            />
                                                        </div>
                                                        <a
                                                            href={config.apiUrl + data.PubFileLink}
                                                            target="_blank"
                                                            className="button causes-item__button button--primary"
                                                            download
                                                        >
                                                            Download
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </MainContainer>
            ) : (
                <Preloader />
            )}
        </>
    );
}
