import React from 'react';
import './indicator.css';

export default function ActivityIndicator() {
    return (
        <>
            <div className="linear-activity">
                <div className="indeterminate"></div>
            </div>
        </>
    );
}
