import { Link } from 'react-router-dom';
import React from 'react';

function LinkButton({ to = null, children, direction = 'colunm', ...rest }) {
    return (
        <Link
            to={to}
            {...rest}
            style={{
                ...(direction === 'row' && {
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }),
            }}
        >
            {children}
        </Link>
    );
}

export default React.memo(LinkButton);
