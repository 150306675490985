import React from 'react';
import { Link } from "react-router-dom";

export default function InfographicsCard({ title, Image ,FileFormat,FileLink}) {
    return (
        <div class="col-md-6 col-lg-4">
            <div class="causes-item causes-item--primary">
                <div class="causes-item__body">
                    <div class="causes-item__top">
                        <h6 class="causes-item__title">{title}</h6>
                    </div>
                    <div class="causes-item__img">
                        <div class="causes-item__badge" style={{ backgroundColor: '#FA6017' }}>
                            {FileFormat}
                        </div>
                        <img class="img--bg" src={Image} alt="img" />
                    </div>
                    <a href={FileLink} target="_blank" download>
                        <div class="button causes-item__button button--primary">
                            Download
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
